export class StockCategory{
    public id= 0;
    public StockCategoryName  = "";
    public Status = "";
    public created_at = "";
    public updated_at = "";
    public deleted_at = "";
    constructor(fields?:{
        id: number;
        StockCategoryName : string;
        Status : string;
        created_at : string;
        updated_at : string;
        deleted_at : string;
    }){
        Object.assign(this,fields);
    }
}