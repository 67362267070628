export class Group {
    public id = 0;
    public ad = "";
    public created_at = "";
    public updated_at = "";
    public deleted_at = "";
    constructor(fields?:{
        id : number;
        ad : string;
        created_at : string;
        updated_at : string;
        deleted_at : string;
    }){
        Object.assign(this,fields);
    }
}