export class Model {
    public id = 0;
    public ad = "";
    public created_at = "";
    public updated_at = "";
    public deleted_at = "";
    public marka_id = 0;
    constructor(fields?:{
        id : number;
        ad : string;
        marka_id: number;
        created_at : string;
        updated_at : string;
        deleted_at : string;
    }){
        Object.assign(this,fields);
    }
}