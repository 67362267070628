import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Brand } from '../../_models/stock-management/brand';
import { Model } from '../../_models/stock-management/model';
import { StockCategory } from '../../_models/stock-management/stock-category';
import { HttpClientService } from '../http-client.service';

const apiUrl = `${environment.apiUrl}model`;

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  constructor(private httpService : HttpClientService) { }

  getAll(): Promise<Array<Model>>{
    return this.httpService.Get<any>(apiUrl, '').then(response => {
          if (response.model) {
              const mapItems = (data : Model[]) : any=>{
                  return data.map(x=> new Model({
                    id: x.id,
                    ad: x.ad,
                    marka_id: x.marka_id,
                    created_at: x.created_at,
                    updated_at: x.updated_at,
                    deleted_at: x.deleted_at
                  }));
              }
              return mapItems(response.model);
          }
          return new Array<Model>();
      });
  }

  save(data: {ad: string, marka_id: number}): Promise<any> {
    return this.httpService.Insert(apiUrl, data).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

  delete(id: number): Promise<any> {
    return this.httpService.Delete(apiUrl, id).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

  edit(data: {id: number, marka_id: number, ad: string}): Promise<any> {
    return this.httpService.Update(apiUrl, data).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

}
