import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Cari } from '../../_models/accounting-management/cari';
import { Kasa } from '../../_models/accounting-management/kasa';
import { HttpClientService } from '../http-client.service';

const apiUrl = `${environment.apiUrl}kasakart`;

@Injectable({
  providedIn: 'root'
})
export class KasaService {

  constructor(private httpService : HttpClientService) { }

  getAll(): Promise<Array<Kasa>>{
    return this.httpService.Get<any>(apiUrl, '').then(response => {
          if (response.kasaKart) {
              const mapItems = (data : any[]) : any=>{
                  return data.map(x=> new Kasa({
                    id: x.id,
                    ad: x.ad,
                    bakiye: x.bakiye,
                    durum: x.durum,
                    created_at: x.created_at,
                    updated_at: x.deleted_at,
                    deleted_at: x.deleted_at
                  }));
              }
              return mapItems(response.kasaKart);
          }
          return new Array<Kasa>();
      });
  }

  save(data:Kasa): Promise<any> {
    return this.httpService.Insert(apiUrl, data).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

  delete(id: number): Promise<any> {
    return this.httpService.Delete(apiUrl, id).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

  edit(data: Kasa): Promise<any> {
    return this.httpService.Update(apiUrl, data).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

}
