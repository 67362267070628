export class Brand {
    public Id = 0;
    public Name = "";
    public CreatedDate = "";
    public UpdatedDate = "";
    public DeletedDate = "";
    constructor(fields?:{
        Id : number;
        Name : string;
        CreatedDate : string;
        UpdatedDate : string;
        DeletedDate : string;
    }){
        Object.assign(this,fields);
    }
}