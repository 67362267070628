import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StockCategory } from '../../_models/stock-management/stock-category';
import { HttpClientService } from '../http-client.service';

const apiUrl = `${environment.apiUrl}stokkategori`;

@Injectable({
  providedIn: 'root'
})
export class StockCategoryService {

  constructor(private httpService : HttpClientService) { }

  getAll() : Promise<Array<StockCategory>>{
    return this.httpService.Get<any>(apiUrl,'').then(response=>{
        if(response){
          return response.stokKategori.map(x=>{
            return new StockCategory({
              id: x.id,
              Status : x.durum,
              StockCategoryName : x.stokKategoriAd,
              created_at: x.created_at,
              updated_at: x.updated_at,
              deleted_at: x.deleted_at
            })
          })
        }
        return new Array<StockCategory>();
    });
  }
  
  save(data: {stokKategoriAd: string}): Promise<any> {
    return this.httpService.Insert(apiUrl, data).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

  delete(id: number): Promise<any> {
    return this.httpService.Delete(apiUrl, id).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

  edit(data: {id: number, stokKategoriAd: string}): Promise<any> {
    return this.httpService.Update(apiUrl, data).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }
}
