import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Brand } from '../../_models/stock-management/brand';
import { StockCategory } from '../../_models/stock-management/stock-category';
import { HttpClientService } from '../http-client.service';

const apiUrl = `${environment.apiUrl}marka`;

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(private httpService : HttpClientService) { }

  getAll(): Promise<Array<Brand>>{
    return this.httpService.Get<any>(apiUrl, '').then(response => {
          if (response.marka) {
              const mapItems = (data : any[]) : any=>{
                  return data.map(x=> new Brand({
                    Id: x.id,
                    Name: x.ad,
                    CreatedDate: x.created_at,
                    UpdatedDate: x.deleted_at,
                    DeletedDate: x.deleted_at
                  }));
              }
              return mapItems(response.marka);
          }
          return new Array<Brand>();
      });
  }

  save(data: {ad: string}): Promise<any> {
    return this.httpService.Insert(apiUrl, data).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

  delete(id: number): Promise<any> {
    return this.httpService.Delete(apiUrl, id).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

  edit(data: {id: number, ad: string}): Promise<any> {
    return this.httpService.Update(apiUrl, data).then(response => {
      return response;
    }).catch(err => {
      console.log(err);
    });
  }

}
